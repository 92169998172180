import React from "react";
import "./component-css/partner.css";

const ComingSoon = () => {
  return (
    <>
      <div className="partner-with-us">
        <div className="txt-container">
          <h1>Be a Remark Dealer</h1>
          <p>Join the EV revolution</p>
          <a class="btn" href="/partner#apply-form">
            Let's partner up
          </a>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-6 img-contain">
              <img
                src="https://remarkev.com/assets/partner-img-1-min.jpg"
                alt="..."
                class="img-fluid"
              />
            </div>
            <div className="col-6 img-contain">
              <img
                src="https://remarkev.com/assets/partner-img-2-min.jpg"
                alt="..."
                class="img-fluid"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6 img-contain">
              <img
                src="https://remarkev.com/assets/partner-img-3-min.jpg"
                alt="..."
                class="img-fluid"
              />
            </div>
            <div className="col-6 img-contain">
              <img
                src="https://remarkev.com/assets/partner-img-4-min.jpg"
                alt="..."
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="info-text page-width">
          <div className="primary"> Become a dealer</div>
          <div className="secondary">and spark up your life!</div>
          <p class="rte">
            The Electric scooter industry is affordable, sustainable and <br />{" "}
            growing. A new model is emerging and you can make the <br /> most of
            it.
          </p>
        </div>
        <div id="apply-form" class="form-container">
          <div className="quarnery text-center">Join us Now</div>

          <form action="" class="pageForm">
            <div className="form-item">
              <input
                name="name"
                placeholder="Full Name"
                type="text"
                required=""
                value=""
              />
            </div>
            <div class="form-item">
              <input
                name="phone"
                placeholder="Phone"
                required=""
                type="tel"
                value=""
              />
            </div>
            <div class="form-item">
              {" "}
              <input
                name="email"
                placeholder="Email"
                type="text"
                value=""
                fdprocessedid="ogtbxc"
              ></input>
            </div>
            <div class="form-item">
              {" "}
              <input
                name="address"
                placeholder="Address"
                type="text"
                value=""
                fdprocessedid="mwazoi"
              ></input>
            </div>
            <div class="form-item">
              {" "}
              <input
                name="pincode"
                placeholder="Pincode"
                type="tel"
                value=""
                fdprocessedid="5tdeqk"
              ></input>
            </div>
            <div class="form-item">
              <label for="person">Dealer/ Investor/ Distributor ?</label>
              <select
                name="person"
                id="person"
                required=""
                fdprocessedid="n8y47"
              >
                <option value>Select Person</option>
                <option value="dealer">Dealer</option>
                <option value="investor">Investor</option>
                <option value="distributor">Distributor</option>
              </select>
            </div>
            <div class="form-item">
              <label for="state">Enter State</label>
              <select
                name="state"
                id="state"
                required=""
                fdprocessedid="7742dr"
              >
                <option value="">Select State</option>
                <option value="Andaman and Nicobar Islands">
                  Andaman and Nicobar Islands
                </option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value>Select State</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Dadra and Nagar Haveli">
                  Dadra and Nagar Haveli
                </option>
                <option value="Daman and Diu">Daman and Diu</option>
                <option value="Delhi">Delhi</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Ladakh">Ladakh</option>
                <option value="Lakshadweep">Lakshadweep</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Puducherry">Puducherry</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
              </select>
            </div>
            <div class="form-item">
              <label for="city">Enter City</label>
              <input
                list="cityList"
                name="city"
                id="city"
                value=""
                fdprocessedid="r7n5ui"
              />
              <datalist id="cityList">
                <option value="">Select City</option>
              </datalist>
            </div>
            <div class="form-item form-submit">
              <button type="submit" class="btn" fdprocessedid="wbelz">
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="complatins-area">
          <div className="info-container">
            <div class="txt-container">
              <p class="">
                If you are interested, we would love to partner with you. Enter
                your details above so we can reach you asap.
              </p>
              <h3 class="tertiary">Complaints</h3>
              <p class="">
                If you are facing any concerns or issues or would like to
                recommend something to us, drop us a mail at{" "}
                <strong>
                  <a class="email-phone" href="mailto: enquiry@remarkev.com">
                    enquiry@remarkev.com
                  </a>
                </strong>
              </p>
              <h3 class="tertiary">Check out our dealer network</h3>
              <p class="">
                Remark stores are wide-spread all over India. Find out more
                about our presence across the country and{" "}
                <strong>
                  <a class="email-phone" href="/locate">
                    {" "}
                    locate{" "}
                  </a>
                </strong>{" "}
                us for in-person enquiries at your nearest dealership!
              </p>
            </div>
          </div>
        </div>
        <article id="steps" class="info-text page-width">
          <div class="prebooking-container">
            <h2 class="secondary">
              <span class="primary">Pre book</span> in 3 easy steps @{" "}
              <a class="" href="/prebook#termsAndConditions">
                ₹999*
              </a>
            </h2>
          </div>
          <div class="steps-container">
            <div class="step-item">
              <div class="icon-container icon-1">
                <i class="material-icons large">location_city</i>
              </div>
              <div class="icon-txt prepage">
                Select your city <br /> &amp; E-Scooter
              </div>
            </div>
            <div class="step-item">
              <div class="icon-container icon-2">
                <i class="material-icons large">payment</i>
              </div>
              <div class="icon-txt prepage">
                Pay Rs. 999 <br /> &amp; confirm order
              </div>
            </div>
            <div class="step-item">
              <div class="icon-container icon-3">
                <i class="material-icons large">favorite</i>
              </div>
              <div class="icon-txt prepage">
                Pay &amp; Pickup your favourite <br />{" "}
                <a class="" href="/prebook#termsAndConditions">
                  E-scooter*
                </a>
              </div>
            </div>
          </div>
        </article>
        {/* welcome section */}
        <section className="my-5">
          <div className="container">
            <h3 className="fw-bold">
              Welcome to Remark: Your Premier Electric Scooter Dealership in
              India
            </h3>
            <p className="my-3">
              At Remark, we pride ourselves on being at the forefront of the
              electric revolution in India. As a leading electric scooter
              dealership and distributor, we are committed to providing our
              customers with a sustainable, efficient, and stylish mode of
              transportation. Our range of electric scooters is not just a
              testament to modern design but also to eco-friendly technology.
            </p>
            <h3 className="fw-bold">
              Why Choose Remark for Your Electric Scooter Needs?
            </h3>
            <div className="container">
              <p className="mt-3">
                <strong>Wide Range of Products : </strong> Our collection of
                electric scooters caters to diverse needs and preferences.
                Whether you're a student looking for an economical ride to
                college or a professional in need of a reliable daily commute,
                Remark has the perfect electric scooter for you.
              </p>
              <p className="my-3">
                <strong>Eco-Friendly and Efficient : </strong> Embracing the
                green revolution, our electric scooters are designed to reduce
                carbon footprints while delivering optimal performance. They are
                efficient, cost-effective, and a step towards a cleaner, greener
                India.
              </p>
              <p>
                <strong>Unmatched Quality and Service : </strong> At Remark,
                quality is our top priority. Our electric scooters undergo
                rigorous testing to ensure reliability and durability. Our
                dedicated service team is always ready to assist you with
                maintenance and support.
              </p>
              <p className="my-3">
                <strong>Accessible and Convenient : </strong> With dealerships
                spread across major cities in India, accessing our range of
                electric scooters is easy. We also offer online consultations
                and bookings for added convenience.
              </p>
            </div>
          </div>
        </section>
        <section className="container">
          {/* join */}
          <div className="mt-4">
            <h3 className="fw-bold">Join the Electric Movement with Remark</h3>
            <p className="">
              Our vision at Remark is to redefine urban mobility in India. By
              choosing an electric scooter from our dealership, you're not just
              buying a vehicle; you're becoming part of a sustainable movement.
              Enjoy a seamless, eco-friendly ride and experience the future of
              transportation with Remark.
            </p>
            <p className="mt-3">
              Join us in this journey towards a greener and more sustainable
              future. Visit our showroom or contact us today to learn more about
              our electric scooters and how you can make a difference with your
              choice of commute.
            </p>
          </div>
        </section>
      </div>
    </>
  );
};

export default ComingSoon;
