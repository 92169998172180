import React from "react";

function DragSection(payload) {
  const { data } = payload;
  const { image } = data;

  return (
    <section
      data-animname="fade-in-up"
      title="Drag"
      id="drag"
      className="page-width--flush"
    >
      <div className="img-container">
        <img src={`/assets/${image}`} alt={`${image}`} />
      </div>
    </section>
  );
}

export default DragSection;