import { useEffect } from "react";
import "./index.css";
import "./animation.css";
import "./utility-class.css";
import "./variables.css";
import "slick-carousel/slick/slick.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Product from "./components/Product";
import About from "./components/About";
import Locate from "./components/Locate";
import Partner from "./components/Partner";
import Enquire from "./components/Enquire";
import Product_Aten_Plus from "./components/productComponents/Product_Aten_Plus";
import { Route, Routes } from "react-router-dom";
import animationObserver from "./library/animationObserver";
import "./script";
import ProductAten from "./components/productComponents/Product_Aten";
import "./script";
import ProductAtenplus from "./components/productComponents/Product_Aten_Plus";
import ProductHiEnergy from "./components/productComponents/Product_HiEnergy";
import ProductHiEnergyDlx from "./components/productComponents/Product_HighEnergyDlx"
import ProductHighEnergyPlus from "./components/productComponents/Product_HighEnergyPlus";
import ProductHipHop from "./components/productComponents/Product_HipHop";
import ProductHipHopDlx from "./components/productComponents/Product_HipHopDlx";
import ProductHipHopPlus from "./components/productComponents/Product_HipHopPlus";
import ProductElectiva from "./components/productComponents/product_Electiva";
import ProductElectivagold from "./components/productComponents/product_Electivagold";
import Move from "./components/miniComponents/Move";
import Contact from "./components/Contact";
import  Prebook from "./components/Prebook";
function App() {
  useEffect(() => {
    animationObserver();
  }, []);

  return (
    <>
      <Header />
      <Routes>
        <Route exact path="/product" element={<Product/>} />
        <Route exact path="/product/aten" element={<ProductAten/>} />
        <Route exact path="/product/aten-plus" element={<ProductAtenplus/>} />
        <Route exact path="/product/hi-energy" element={<ProductHiEnergy/> } />
        <Route exact path="/product/hi-energy-plus" element={<ProductHighEnergyPlus/>} />
        <Route exact path="/product/hi-energy-dlx" element={<ProductHiEnergyDlx/>} />
        <Route exact path="/product/hiphop" element={<ProductHipHop/>} />
        <Route exact path="/product/hiphop-dlx" element={<ProductHipHopDlx/>} />
        <Route exact path="/product/hiphop-plus" element={<ProductHipHopPlus/> } />
        <Route exact path="/product/electiva-star" element={<ProductElectiva/>} />
        <Route exact path="/product/electiva-gold" element={<ProductElectivagold/>} />
        <Route exact path="/about" element={<About/>} />
        <Route exact path="/locate" element={<Locate/>} />
        <Route exact path="/contact" element={<Contact/>} />
        <Route exact path="/prebook" element={<Prebook/>} />
        <Route exact path="/partner" element={<Partner/>} />
        <Route exact path="/enquire" element={<Enquire/>} />
        <Route exact path="/index.html" element={<Home/>} />
        <Route exact path="/home" element={<Home/>} />
        <Route exact path="/" element={<Home/>} />
      </Routes>
      <Footer />
      <Move/>
    </>
  );
}

export default App;
