import React from "react";

function Footer() {
  return (
    <footer>
      <div className="hr"></div>
      <div className="call-email">
        <div
          data-animname="fade-in-up"
          data-animtiming=".5"
          className="number bottom-border"
        >
          Call{" "}
          <a href="tel:+18002098282">
            <strong> 1800 121 2207</strong>{" "}
          </a>
          For Any Assistance
        </div>
        <div
          data-animname="fade-in-up"
          data-animtiming=".5"
          className="email bottom-border"
        >
          <img src="./assets/icon-mail.png" alt="mail icon" />
          <strong>
            <a href="mailto:info@remarkev.com"> info@remarkev.com </a>
          </strong>
        </div>
      </div>

      <div className="footer-grid-container">
        <div className="footer-grid-item footer-grid-item-1">
          <div className="links-container">
            <h3 data-animname="fade-in-up" data-delay=".1" data-animtiming="1">
              E-VEHICLE
            </h3>
            <ul className="links">
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">Aten</a>
              </li>
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">Aten Plus</a>
              </li>
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">Hip Hop</a>
              </li>
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">Electiva Star</a>
              </li>
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">Electiva Gold</a>
              </li>
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">Hip Hop Plus</a>
              </li>
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">Hip Hop Dlx</a>
              </li>
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">Hi Energy</a>
              </li>
             
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">Hi Energy Plus</a>
              </li>
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">High Energy ltd</a>
              </li>
           
            </ul>
          </div>
        </div>

        <div className="footer-grid-item footer-grid-item-2">
          <div className="links-container">
            <h3 data-animname="fade-in-up" data-delay=".1" data-animtiming="1">
           REMARK
            </h3>
            <ul className="links">
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">About Us</a>
              </li>
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">Dealership</a>
              </li>
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">Saving Calculator</a>
              </li>
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">Prebook</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-grid-item footer-grid-item-3">
          <div className="links-container">
            <h3 data-animname="fade-in-up" data-delay=".1" data-animtiming="1">
             STORIES
            </h3>
            <ul className="links">
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">Test Ride</a>
              </li>
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">Stories Calculator</a>
              </li>

            </ul>
          </div>
        </div>
        <div className="footer-grid-item footer-grid-item-3">
          <div className="links-container">
            <h3 data-animname="fade-in-up" data-delay=".1" data-animtiming="1">
             SUPPORT
            </h3>
            <ul className="links">
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">FAQs</a>
              </li>
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">Downloads</a>
              </li>
              <li
                data-animname="fade-in-up"
                data-animtiming=".5"
                data-delay=".3"
                className="link"
              >
                <a href="/">Contact</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-grid-item footer-grid-item-4">
          <div className="social-area">
            <div className="newsletter">
              <form action="">
                <label htmlFor="subscriber" class="subscriber">
                  Sign up to get promos, giveaways and product news
                </label>
                <div className="form-item">
                  <input type="email" name="subscriber" id="subscriber" />
                  <button className="feed" type="submit">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.0519 14.8285L13.4661 16.2427L17.7087 12L13.4661 7.7574L12.0519 9.17161L13.8803 11H6.34318V13H13.8803L12.0519 14.8285Z"
                        fill="currentColor"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 19C1 21.2091 2.79086 23 5 23H19C21.2091 23 23 21.2091 23 19V5C23 2.79086 21.2091 1 19 1H5C2.79086 1 1 2.79086 1 5V19ZM5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                </div>
              </form>
            </div>

            <div className="social">
              <h3>FOLLOW US ON</h3>

              <div className="social-icons-container">
                <div className="social-icon">
                  <a
                    target="_blank"
                    aria-label="fb-link"
                    href="https://www.facebook.com/remarkelectric"
                  >
                    <img src="./assets/social-icon-fbs.png" alt="fb icon" />
                  </a>
                </div>
                <div className="social-icon">
                  <a
                    target="_blank"
                    aria-label="insta-link"
                    href="https://www.instagram.com/remarkelectric/"
                  >
                    <img
                      src="./assets/social-icon-instas.png"
                      alt="insta icon"
                    />
                  </a>
                </div>
                <div className="social-icon">
                  <a
                    target="_blank"
                    aria-label="youtube-link"
                    href="https://www.youtube.com/channel/UCstgHWg9wLQiPEvT18J8I8w"
                  >
                    <img src="./assets/social-icon-youtube.png" alt="youtube icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hr"></div>
      <p className="copyright">
        All information is subject to specific conditions | Copyright © 2020
        Remark. All rights reserved.
      </p>
    </footer>
  );
}

export default Footer;
