import React from 'react'
import { Helmet } from "react-helmet";
import AboutFeature from './mainComponent/AboutFeature'
const About = () => {
  return (
	  <div>
          <Helmet>
              <title>About Us - Remarkev.com</title>
              <meta name="description" content="Lorem ipsum dolor sit amet" />
              <link rel="canonical" href=" https://www.remarkev.com " />
              <meta name="google-site-verification" content="COLodQOcDaSqrNoAQHg5K0J_BEO9H-W0RHSW0Dk5eCc" />

          </Helmet>
  <AboutFeature/>
	</div>
  )
}

export default About

