import React from "react";

function Feature(payload) {
  const { image, heading, description, index } = payload;

  return (
    <div
      data-animname="fade-in-up"
      data-delay={index * 0.2}
      className={`feature feature-${index}`}
    >
      <div className="img-container">
        <img src={`/assets/${image}`} alt={image} />
      </div>
      <div className="txt-container">
        <h3 class="rate">{heading}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default Feature;
