import React from "react";

function DatasheetDownload() {
  return (
    <div className="download">
      Click{" "}
      <a href="./assets/Remark-Catalogue-Green.pdf" download>
        {" "}
        here
      </a>{" "}
      to download datasheet
    </div>
  );
}

export default DatasheetDownload;
