import React from 'react'
import "../component-css/AboutFeature.css";
 
const AboutFeature = () => {
  return (
    <>
      <div id="about">
  <article class="hero">
    <h1 class="heading text-center">ABOUT US</h1>
    <section id="youtubeIframeContainer">
      <iframe
        class="youtubeIframe"
        src="https://www.youtube.com/embed/v-iFTcpLNPg?"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </section>
    </article>
    <p
    data-animname="fade-in-up"
    data-delay=""
    class="about_rte text-center info-container about-txt fade-in-up"
    // style="animation: auto ease 0s 1 normal none running none"
  >
    Remark is an electric scooter manufacturer at the forefront of the
    e-revolution. We believe that the future belongs to electric mobility where
    innovation &amp; quality play a significant role. We currently design and
    engineer some of the most powerful and versatile e-scooters in the nation at
    the most affordable prices.
  </p>
  <article class="key-priciples">
    <div class="keys-container">
      <div class="key-item key-item-1">
        <div class="abouthead txt-container">
          <h3
            data-animname="fade-in-up"
            data-delay=""
            class="secondary fade-in-up"
            // style="animation: auto ease 0s 1 normal none running none"
          >
            SUSTAINABLE ENVIRONMENT
          </h3>
        </div>
        <div class="img-container">
          <img
            data-animname="fade-in-up"
            data-delay=".2"
            src="/assets/about-sustain.png"
            alt="about-sustainable-environment.png"
            class="fade-in-up"
            // style="animation: auto ease 0s 1 normal none running none"
          />
        </div>
      </div>
      <div class="key-item key-item-2">
        <div class="aboutheads txt-container">
          <h3
            data-animname="fade-in-up"
            data-delay=""
            class="secondary fade-in-up"
            // style="animation: auto ease 0s 1 normal none running none"
          >
            HEALTHY CLIMATE AND COMMUNITIES
          </h3>
        </div>
        <div class="img-container">
          <img
            data-animname="fade-in-up"
            data-delay=".2"
            src="/assets/abouthealthy.png"
            alt="about-healthy-climate.png"
            class="fade-in-up"
            // style="animation: auto ease 0s 1 normal none running none"
          />
        </div>
      </div>
    </div>
  </article>
  <article class="our-mission">
    <div class="mission">
      <h1
        data-animname="fade-in-up"
        data-delay=""
        class="heading fade-in-up"
        // style="animation: auto ease 0s 1 normal none running none"
      >
        OUR MISSION
      </h1>
      <p
        data-animname="fade-in-up"
        data-delay=".1"
        class="rte info-container fade-in-up"
        // style="animation: auto ease 0s 1 normal none running none"
      >
        Our mission is to make e-mobility a lifestyle!<br /><br />Our vision is
        to support an equitable, sustainable and safe future in India by
        introducing responsible, cool, funky electric scooters with no
        compromises!
      </p>
    </div>
    <div 
      data-animname="fade-in-up"
      data-delay=".3"
      class="img-container fade-in-up mission r"
      // style="animation: auto ease 0s 1 normal none running none"
    >
      <img
        src="/assets/aboutmission.jpg"
        alt="about-our-mission-img.jpg"
      />
    </div>
  </article>
  <article
    data-animname="fade-in-up"
    data-delay=""
    class="our-belief fade-in-up"
    // style="animation: 0.5s ease 0s 1 normal forwards running fade-in-up"
  >
    <h1 class="heading text-center">OUR BELIEF</h1>
    <div class="heading-with-icons">
      <img
        data-animname="fade-in-up"
        data-delay=""
        src="/assets/electricsign.png"
        alt="electric-sign-black.png"
        class="fade-in-up"
        // style="animation: 0.5s ease 0s 1 normal forwards running fade-in-up"
      />
 <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="10">
      <h2 class="sub_head">STYLE</h2>
    </div>
    <div class="carousel-item" data-bs-interval="10">
    <h2 class="sub_head">QUALITY</h2>
    </div>
    <div class="carousel-item">
     <h2 class="sub_head">VALUE</h2>
    </div>
  </div>
</div>
      
     
      
      <img
        data-animname="fade-in-up"
        data-delay=""
        src="/assets/electricsign.png"
        alt="electric-sign-black.png"
        class="fade-in-up"
        // style="animation: 0.5s ease 0s 1 normal forwards running fade-in-up"
      />
    </div>
    <p
      data-animname="fade-in-up"
      data-delay=""
      height="20"
      class="about_heading  text-center  fade-in-up"
      // style="animation: 0.5s ease 0s 1 normal forwards running fade-in-up"
    >
      Introducing a new way to buzz around the town in style with no compromises
      and become a <br />part of the new era of electric!
    </p>
  </article>
  <article class="leadership">
    <h1 class="heading text-center">LEADERSHIP</h1>
    <div class="imgs-grid-container">
      <div class="img-grid-item">
        <img
          src="/assets/electric-sign.png"
          data-animname="fade-in-up"
          data-delay="0"
          alt="/assets/electric-sign.png"
          class="fade-in-up"
          // style="animation: auto ease 0s 1 normal none running none"
        />
        <p
          class="title fade-in-up"
          data-animname="fade-in-up"
          data-delay="0"
          // style="animation: auto ease 0s 1 normal none running none"
        >
          Person 1
        </p>
      </div>
      <div class="img-grid-item">
        <img
          src="/assets/electric-sign.png"
          data-animname="fade-in-up"
          data-delay="0.05"
          alt="/assets/electric-sign.png"
          class="fade-in-up"
          // style="animation: auto ease 0s 1 normal none running none"
        />
        <p
          class="title fade-in-up"
          data-animname="fade-in-up"
          data-delay="0.1"
          // style="animation: auto ease 0s 1 normal none running none"
        >
          Person 2
        </p>
      </div>
      <div class="img-grid-item">
        <img
          src="/assets/electric-sign.png"
          data-animname="fade-in-up"
          data-delay="0.1"
          alt="/assets/electric-sign.png"
          class="fade-in-up"
          // style="animation: auto ease 0s 1 normal none running none"
        />
        <p
          class="title fade-in-up"
          data-animname="fade-in-up"
          data-delay="0.2"
          // style="animation: auto ease 0s 1 normal none running none"
        >
          Person 3
        </p>
      </div>
      <div class="img-grid-item">
        <img
          src="/assets/electric-sign.png"
          data-animname="fade-in-up"
          data-delay="0.15000000000000002"
          alt="/assets/electric-sign.png"
          class="fade-in-up"
          // style="animation: auto ease 0s 1 normal none running none"
        />
        <p
          class="title fade-in-up"
          data-animname="fade-in-up"
          data-delay="0.30000000000000004"
          // style="animation: auto ease 0s 1 normal none running none"
        >
          Person 4
        </p>
      </div>
    </div>
    <div class="about-content">
      <p
        data-animname="fade-in-up"
        data-delay=".3"
        class="text-center fade-in-up"
        // style="animation: auto ease 0s 1 normal none running none"
      >
        Remark, an upcoming two-wheeler transportation in India, was not just a
        mere idea but a perspective of creating something sustainable for our
        society as well as benefiting the customers. Lockdown 2020 has proven to
        be a fruitful time for everyone across <br /> the globe.<br /><br />While the
        country went on mute and away from the daily hustle, a group of four
        friends amidst the mere silence of the city surrounding them, debated on
        the importance of going eco-logical with vehicles. Amazed by the way
        nature responded to empty roads, fewer vehicles and decline in the level
        of pollution, they decided to create a change in the transportation
        industry. During this span of four-five months they conceived the idea
        to originate the country’s new-age technology in transport with their
        electric scooters.<br /><br />Talking a little more about them, Sandeep
        Arora and Rohit Juneja have been professionally connected for the past 8
        years curating ice-cream carts for top brands all over the country. On
        the other hand, Rohit Malik and Akhil Jain have been partners in their
        stationery products business for the past 20 years.<br /><br />It was
        right when these four came up together and made their friendship turn
        into a forthcoming big-ticket business partnership, and Remark was
        born.<br /><br />Their vision with the brand is to ‘create a mark in
        people’s lives that cannot be calculated in numbers’.<br /><br /><br /><br />
      </p>
    </div>
  </article>
    </div>
    </>
  )
}

export default AboutFeature

