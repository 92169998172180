import React from 'react'
 
const Prebook = () => {
  return (
  <div id="pre-book">
  <div class="hero">
  <div class="img-container">
  <img src="/assets/prebook.png" alt=""/>
  </div>
  </div>
  <article class="data-container  page-width info-text">
  <div class="prebook-container">
  <h2 class="secondary">
  <span class="primary">
  Pre book</span> your favorite bike and get it delivered from nearest<span> Remark <br />  store</span>
   @ <a class="" href="/prebook#termsAndConditions">₹999*</a>
   </h2>
   </div>
   </article>
   <div class="form-container page-width"><form method="POST" id="partner-apply-form" class="pageForm">
   <div class="form-item">
   <input name="name" placeholder="Full Name" type="text" required="" value="" fdprocessedid="9fzpva"/>
   </div><div class="form-item">
   <input name="phone" placeholder="Phone" required="" type="tel" value="" fdprocessedid="4r532"/>
   </div>
   <div class="form-item">
   <input name="email" placeholder="Email" type="email" value="" fdprocessedid="0g5779"/>
   </div>
   <div class="form-item">
   <input name="message" placeholder="Message" type="text" value="" fdprocessedid="3s50zf"/>
   </div>
   <div class="form-item">
   <label for="refferedBy">Reffered By</label>
   <select name="refferedBy" id="refferedBy" fdprocessedid="bozozs">
   <option value="">Select Reffered</option>
   <option value="facebook">facebook</option>
   <option value="instagram">instagram</option>
   <option value="youtube">youtube</option>
   <option value="twitter">twitter</option>
   <option value="other">other</option>
   </select>
   </div>
   <div class="form-item">
   <label for="state">Enter State</label>
   <select name="state" id="state" required="" fdprocessedid="xcrhj">
   <option value="">Select State</option>
   <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
   <option value="Andhra Pradesh">Andhra Pradesh</option>
   <option value="Arunachal Pradesh">Arunachal Pradesh</option>
   <option value="Assam">Assam</option>
   <option value="Bihar">Bihar</option>
   <option value="Chandigarh">Chandigarh</option>
   <option value="Chhattisgarh">Chhattisgarh</option>
   <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
   <option value="Daman and Diu">Daman and Diu</option>
   <option value="Delhi">Delhi</option>
   <option value="Goa">Goa</option>
   <option value="Gujarat">Gujarat</option>
   <option value="Haryana">Haryana</option>
   <option value="Himachal Pradesh">Himachal Pradesh</option>
   <option value="Jammu and Kashmir">Jammu and Kashmir</option>
   <option value="Jharkhand">Jharkhand</option>
   <option value="Karnataka">Karnataka</option>
   <option value="Kerala">Kerala</option>
   <option value="Ladakh">Ladakh</option>
   <option value="Lakshadweep">Lakshadweep</option>
   <option value="Madhya Pradesh">Madhya Pradesh</option>
   <option value="Maharashtra">Maharashtra</option>
   <option value="Manipur">Manipur</option>
   <option value="Meghalaya">Meghalaya</option>
   <option value="Mizoram">Mizoram</option>
   <option value="Nagaland">Nagaland</option>
   <option value="Odisha">Odisha</option>
   <option value="Puducherry">Puducherry</option>
   <option value="Punjab">Punjab</option>
   <option value="Rajasthan">Rajasthan</option>
   <option value="Sikkim">Sikkim</option>
   <option value="Tamil Nadu">Tamil Nadu</option>
   <option value="Telangana">Telangana</option>
   <option value="Tripura">Tripura</option>
   <option value="Uttar Pradesh">Uttar Pradesh</option>
   <option value="Uttarakhand">Uttarakhand</option>
   <option value="West Bengal">West Bengal</option>
   </select></div><div class="form-item">
   <label for="city">Enter City</label>
   <input list="cityList" name="city" id="city" value="" fdprocessedid="yxg0ei"/>
   <datalist id="cityList">
   <option value="">Select City</option>
   </datalist></div><div class="form-item">
   <label for="model">Select Model</label>
   <select name="model" id="model" required="" fdprocessedid="tyhft8">
   <option value="">Select Model</option><option value="aten">Aten</option>
   <option value="aten-plus">Aten Plus</option><option value="hip-hop">Hip Hop</option>
   <option value="hip-hop-plus">Hip Hop Plus</option><option value="hip-hop-dlx">Hip Hop Dlx</option>
   <option value="high-energy">High Energy</option><option value="high-energy-plus">High Energy Plus</option>
   <option value="high-energy-ld">High Energy LD</option>
   </select></div><div class="form-item">
    <label for="modelVariant">Select Variant</label>
    <select name="modelVariant" id="modelVariant" required="" fdprocessedid="ynd1tl">
        <option value="Select Model First">Select Model First</option>
        </select>
        </div>
        <div class="form-item form-submit">
            <button type="submit" class="btn" fdprocessedid="mqx9lb">Submit</button>
            </div>
            <div class="form-item">
                </div>
                </form>
                </div>
                <article id="steps" class="info-text page-width">
                    <div class="prebooking-container">
                        <h2 class="secondary">
                            <span class="primary">Pre book</span> in 3 easy steps @ <a class="" href="/prebook#termsAndConditions">₹999*</a>
                            </h2>
                            </div>
                            <div class="steps-container">
                                <div class="step-item">
                                    <div class="icon-container icon-1">
                                        <i class="material-icons large">location_city</i>
                                        </div>
                                        <div class="icon-txt prepage">Select your city <br /> &amp; E-Scooter</div>
                                        </div>
                                        <div class="step-item">
                                            <div class="icon-container icon-2"> 
                                            <i class="material-icons large">payment</i>
                                            </div>
                                            <div class="icon-txt prepage">Pay Rs. 999 <br /> &amp; confirm order</div>
                                            </div>
                                            <div class="step-item">
                                                <div class="icon-container icon-3">
                                                    <i class="material-icons large">favorite</i>
                                                    </div>
                                                    <div class="icon-txt prepage">Pay &amp; Pickup your favourite <br /> <a class="" href="/prebook#termsAndConditions">E-scooter*</a>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    </article>
                                                    <article id="termsAndConditions">
                                                        <ul class="">
                                                            <h2 class="term">*Terms &amp; Conditions</h2>
                                                            <li>Prices are EX. showroom</li>
                                                            <li>Prices are EX. showroom</li>
                                                            </ul>
                                                            </article>
                                                            <article class="help page-width  info-text">
                                                                <h2 class="secondary">Need Help?</h2>
                                                                <div class="preteam-container">
                                                                    <div class="help-item">
                                                                        <h3 class="tertiary">Support</h3
                                                                        ><p> <a class="email-phone" href="mailto: support@remarkev.com">support@remarkev.com</a></p>
                                                                        </div>
                                                                        <div class="help-item">
                                                                            <h3 class="tertiary">Enquiry</h3>
                                                                            <p> <a class="email-phone" href="mailto: enquiry@remarkev.com">enquiry@remarkev.com</a></p>
                                                                            </div>
                                                                            <div class="help-item">
                                                                                <h3 class="tertiary">Sales and customer support</h3>
                                                                                <p> <a class="email-phone" href="tel:+18002098282">1800 121 2207</a></p>
                                                                                </div>
                                                                                </div>
                                                                                </article>
                                                                                </div>
  )
}

export default Prebook
