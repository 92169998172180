import React from "react";
import Slider from "react-slick";
import SliderItem from "./miniComponents/SliderItem";

function Hero_slider({ dataForHeroSlider }) {
  var settings = {
    infinite: true,
    speed: 1500,
    fade: false,
    dots: true,
    cssEase: "ease",
    // autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Slider {...settings} className="hero-slider-container">
      {dataForHeroSlider.map((data, index) => (
        <SliderItem data={{...data, index}} key={index} />
      ))}
    </Slider>
  );
}

export default Hero_slider;
