import React, { useState, useEffect } from "react";

function Calculator() {
  const [dailyCommute, setDailyCommute] = useState(1);
  const [petrolPrice, setPetrolPrice] = useState(50);
  const [trees, setTrees] = useState(0);
  const [co2, setCo2] = useState(0);
  const [money, setMoney] = useState(0);

  let roundOff = (num) => Math.round(num * 100) / 100;

  useEffect(() => {
    setMoney(
      roundOff(
        (7257.7 * dailyCommute + 1.15 * petrolPrice) * 0.5
      ).toLocaleString("en-IN")
    );

    setTrees(
      roundOff(2.4 * dailyCommute + 0.025 * petrolPrice).toLocaleString("en-IN")
    );

    setCo2(
      roundOff(
        (0.26 * dailyCommute + 0.00275 * petrolPrice) * 0.5
      ).toLocaleString("en-IN")
    );
  }, [petrolPrice, dailyCommute]);

  const handleCommute = (e) => {
    setDailyCommute(e.target.value);
  };
  const handlePrice = (e) => {
    setPetrolPrice(e.target.value);
  };

  return (
    <section title="Savings" id="calculator">
      <div className="txt-container" id="text-container">
        <h1 className="heading" id="home-head">How Much Will You  <br /> Save On Fuel?</h1>
        <p className="rte">
          Saving huge on the fuel is what makes Remark extraordinary! You can
          now save money by going all electric <br /> and help the planet along your
          way.
        </p>
      </div>

      <div className="form-container">
        <form action="">
          <fieldset data-animname="fade-in-up" data-delay="">
            <h3 class="calculate-wrap">SELECT YOUR DAILY COMMUTE [In Km]</h3>
            <div className="form-item">
              <label id="label-daliy-commute" htmlFor="daliy-commute">
                <span>{dailyCommute}</span>
                <div className="icon-container">
                  <img src="./assets/icon-scooter.png" alt="" />
                </div>
              </label>
              <input
                onChange={handleCommute}
                type="range"
                step="1"
                value={dailyCommute}
                min="1"
                max="100"
                name="label-daliy-commute"
                id="daliy-commute"
              />
            </div>
          </fieldset>

          <fieldset data-animname="fade-in-up" data-delay=".2">
            <h3 class="calculate-wrap">SELECT FUEL PRICE IN YOUR CITY [Price/Litre]</h3>
            <div className="form-item">
              <label id="label-fuel-price" htmlFor="fuel-price">
                <span>{petrolPrice}</span>
                <div className="icon-container">
                  <img src="./assets/icon-petrol.png" alt="" />
                </div>
              </label>
              <input
                onChange={handlePrice}
                type="range"
                step=".1"
                value={petrolPrice}
                min="50"
                max="100"
                name="label-fuel-price"
                id="fuel-price"
              />
               
            </div>
            
          </fieldset>

        
        </form>
       
      </div>
      <a className="btns" href="#info-container">
            CALCULATE
          </a>
      <div id="info-container" className="info-container">
        <div className="bottom-border title">
          Estimated Savings <br />
          (Over 5 Years)
        </div>

        <div className="infos">
          <div className="info">
            <div className="result" id="fuel-cost-saving">
              ₹ ????✔✔ <span>{money}</span>
            </div>
            <p>Fuel Cost Savings</p>
          </div>
          <div className="info">
            <div className="result" id="tailpipe-co2">
              <span>{co2}</span>???? MILLION GRAMS ✔✔
            </div>
            <p>Tailpipe CO2 Reduction</p>
          </div>
          <div className="info">
            <div className="result" id="trees-saved">
              <span>{trees}</span>???? FULL-GROWN TREES ✔✔
            </div>
            <p>Equivalent to CO2 reduction</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Calculator;
