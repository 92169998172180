import React from "react";
import { Link } from "react-router-dom";
import DatasheetDownload from "../miniComponents/DatasheetDownload";

function ProductWhy({ data }) {
  const { image, heading, text, dataSheetUrl } = data;

  return (
    <section data-animname="fade-in-up" title="Why" id="product-why">
      <article>
        <div className="img-container">
          <img src={`/assets/${image}`} alt={`${image}`} />
        </div>

        <div className="info-container">
          <h2>{heading}</h2>
          <p className="rte"> {text}</p>

          <Link className="btn" to="/enquire">
            Enquire Now
          </Link>

          <DatasheetDownload />
        </div>
      </article>
    </section>
  );
}

export default ProductWhy;
