import React from "react";
import Slider from "react-slick";
import "../miniComponents/FeaturedSlider.css"
function FeaturedSlider(payload) {
  const { settings, data } = payload;
  const { heading, sliderData } = data;

  return (
    <section
      data-animname="fade-in-up"
      data-animtiming="1.2"
      title="Featured Slider"
      id="features-slider-section"
    >
      <div className="slider-container">
        <h2 className="heading">{heading}</h2>

        <Slider {...settings}>
          {sliderData.map((data, index) => {
            const { image, txt } = data;

            return (
              <div className={`slider-item-${index} slider-item`} key={index}>
                <div className="img-container">
                  <img src={`/assets/${image}`} alt={`${image}`} />
                </div>

                <div className="txt-container">
                  <p>{txt}</p>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
}

export default FeaturedSlider;
