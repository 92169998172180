import React, { useEffect } from "react";
import Product_Aten from "./productComponents/Product_Aten";
import animationObserver from "../library/animationObserver";
import Product_Aten_Plus from "./productComponents/Product_Aten_Plus";

function Product() {
  useEffect(() => {
    animationObserver();
  }, []);

  return (
    <>
      <Product_Aten />
      <Product_Aten_Plus />
    </>
  );
}

export default Product;
