import React from 'react'
// import ComingSoon from './ComingSoon'

function Locate() {
	return (
		<>
 <div className="map">
 
 <iframe src="https://www.google.com/maps/d/embed?mid=1tLPkR1luyFZg7NSyUSzwZezfioQyDFQ&ehbc=2E312F" width="100%" height="500"></iframe>
  
 </div>
 </>
	)
}

export default Locate
