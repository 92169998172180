import React from "react";
import Hero_slider from "../Hero_slider";
import DragSection from "../miniComponents/DragSection";
import Feature from "../miniComponents/Feature";
import FeaturedSlider from "../miniComponents/FeaturedSlider";
import ProductVariants from "./ProductVariants";
import ProductDisplayImg from "./ProductDisplayImg";
import ProductWhy from "./ProductWhy";
import Video from "../miniComponents/Video";
import "../productComponents/Product_Aten.css"
function Product_HipHop() {
  const dataForHeroSlider = [
    {
      image: "hiphop1.png",
      textData: [
        {
          color: "#fff",
          heading: "3-8 hrs",
          content: `Charge (0%-100%)`,
        },
        {
          color: "#fff",
          heading: "3 yrs",
          content: "Battery and Motor Warranty",
        },
      ],
    },
    {
      image: "hiphop1.png",
      textData: [
        {
          color: "#fff",
          heading: "3-8 hrs",
          content: `Charge (0%-100%)`,
        },
        {
          color: "#fff",
          heading: "3 yrs",
          content: "Battery and Motor Warranty",
        },
      ],
    },
  ];

  const featureData = [
    {
      image: "icon-scooter.webp",
      heading: "DESIGN",
      description: "Seamless Style. Edgy Looks. Bold Colours.",
    },
    {
      image: "icon-build.webp",
      heading: "BUILD",
      description:
        " Steel Chassis for Maximum Rigidity. Precision pressed steel body panels.",
    },
    {
      image: "icon-battery.webp",
      heading: "RANGE",
      description: "95km on a single charge. 0 to 25% in 1 Hour.",
    },
  ];

  return (
    <main id="poduct-Aten" className="product">
      <section id="hero" title="hero">
        <Hero_slider dataForHeroSlider={dataForHeroSlider} />
      </section>

      {/* <!-- design section --> */}
      <section data-animname="fade-in-up" title="Design" id="design">
        <div className="data-container page-width design-wraper">
          <div className="headings">
            <h2 className="heading">DESIGN</h2>
            <p className="rte" style={{color:"black"}}>Fun is where you choose to begin!</p>
          </div>

          {/* features */}
          <div className="features" style={{ backgroundColor: "#18A3B3" }}>
            {featureData.map((data, index) => {
              const { image, heading, description } = data;

              return (
                <Feature
                  key={description}
                  index={index}
                  image={image}
                  heading={heading}
                  description={description}
                />
              );
            })}
          </div>
        </div>
      </section>

      {/* Drag section */}
      <DragSection
        data={{
          image: "product-img-aten-plus-drag.png",
        }}
      />
      {/* <DragSection
        data={{
          image: "product-img-aten-plus-drag.png",
        }}
      />
      <DragSection
        data={{
          image: "product-img-aten-plus-drag.png",
        }}
      /> */}

      {/* featured slider section */}
      <FeaturedSlider
        settings={{
          infinite: true,
          speed: 1500,
          fade: false,
          dots: true,
          cssEase: "ease",
          autoplay: true,
          autoplaySpeed: 3000,
        }}
        data={{
          heading: "KEY FEATURES",

          sliderData: [
            {
              txt: "LED TailLamps",
              image: "hiphop2.jpg",
            },
            {
              txt: "Remote Key with Central Locking system",
              image: "hiphop3.jpg",
            },
           
            {
              txt: "Alloy Wheels with Telescopic Suspension and Disc Brakes",
              image: "hiphop5.jpg",
            },
            {
              txt: "Digital Instrument Cluster",
              image: "hiphop6.jpg",
            },
            {
              txt: "Backrest with Grab Handle",
              image: "hiphop7.jpg",
            },
          ],
        }}
      />

      {/* Why section  */}
      <ProductWhy
        data={{
          image: "hiphop8.png",
          heading: "Why you should buy?",
          text:
            "What’s more classic than you riding the classic? Go old school with the most authentic and stylish features ever presented in a scooter with Aten, suiting your choices everyday!",
        }}
      />

      {/* Product display section
      <ProductDisplayImg
        data={{
          image: "product-aten-plus-display-img.png",
        }}
      /> */}
      <Video/>

      {/* ProductVarients section  */}
      <ProductVariants
        data={{
          variants: [
            {
              image: "hiphops1.png",
              heading: "Black",
              bgColor: "black",
            },
            {
              image: "hiphoppurple.png",
              heading: "Purple",
              bgColor: "purple",
            },
            {
              image: "hiphopwhite.png",
              heading: "White",
              bgColor: "#DDDDDD",
            }
           
          ],
        }}
      />
    </main>
  );
}

export default Product_HipHop;
