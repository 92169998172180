import React from 'react'
import "../component-css/Video.css"
const Video = () => {
  return (
    <>
<section class="video-wrapper">
<video src="https://remarkev.com/assets/variants/aten-plus/display-video.m4v" title="Electric Vehicles: Will they save or destroy us?" controls autoplay></video>
         </section>
    </>
  )
}

export default Video
