import $ from "jquery";

// appply slick-----------------------------------------------------------
$(document).ready(function () {
  console.log("jquery loaded");
});

//Intersection observer for section-----------------------------------------------------------
let optionsForPageProgress = {
  rootMargin: "-40%",
};

const pageProgressObserver = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    const { title, id } = entry.target;
    const currentItem = $(`#page-progress #progress--${id}`)[0];

    if (entry.intersectionRatio > 0) {
      currentItem.classList.add("visible");
    } else if (entry.intersectionRatio < 0.8) {
      currentItem.classList.remove("visible");
    }
  });
}, optionsForPageProgress);

// page progress
const pageProgressUl = $("#page-progress ul")[0];
const sections = [...$("#home section")];

sections.forEach((section) => {
  if (!pageProgressUl) return;

  if (!section) return;
  const { title, id } = section;
  pageProgressObserver.observe(section);
  pageProgressUl.innerHTML += `<li id="progress--${id}"><a class="" href="#${id}">${title}</a></li>`;
});
