import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
const Contact = () => {
  const navigate = useNavigate();
  const form = useRef();

  const sendEmail = (e) => {
    
    e.preventDefault();
    emailjs.sendForm("service_y4hsrnz","template_8babn7i",form.current,"pF3zFKe8JQATvzsiC")
      .then((res) => {
        console.log(res);
           navigate("/");
      })
      .catch((err) => console.log(err));
  };
 
 
  
  return (
    <>
      <div id="contact">
        <div class="hero">
          <div class="img-container">
            <img
              src="https://remarkev.com/assets/contact-us.jpg"
              alt="contact"
            />
            <div class="txt-container">
              <h1 class="heading">Contact US</h1>
            </div>
          </div>
        </div>
        <article class="reach-us page-width">
          <div id="reach" class="txt-container info-container">
            <h2 class="primary">Reach Us</h2>
            <p class="rite">
              Drop us a mail at{" "}
              <strong>
                {" "}
                <a class="email-phone" href="mailto: info@remarkev.com">
                  {" "}
                  info@remarkev.com{" "}
                </a>{" "}
              </strong>{" "}
              to get in touch with our team or use the form below to reach us.
            </p>
          </div>
        </article>
        <div class="form-container page-width">
          <form
            ref={form}
            onSubmit={sendEmail}
            id="partner-apply-form"
            class="pageForm"
          >
            <div class="form-item">
              <input
                type="text"
                name="user_name"
                placeholder="Full Name"
                required=""
              />
            </div>
            <div class="form-item">
              <input
                type="number"
                name="phone"
                placeholder="Phone"
                required=""
              />
            </div>
            <div class="form-item">
              <input type="email" name="user_email" placeholder="Email" />
            </div>
            <div class="form-item">
              <input
                type="text"
                name="message"
                placeholder="Message"
                required=""
              />
            </div>
            <div class="form-item">
              <input type="text" name="city" placeholder="City" />
            </div>
            <div class="form-item">
              <input
                type="text"
                name="foundFrom"
                placeholder="How you found us? e.g - fb,insta,twitter,youtube, or other"
              />
            </div>
            <div class="form-item form-submit">
              <button type="submit" class="btn" value="Send">
                Submit
              </button>
              {/* <input type="submit" value="Send"/> */}
            </div>
            <div class="form-item"></div>
          </form>
        </div>
        <article class="sales-and-support page-width">
          <div class="txt-container info-container">
            <h2 class="ref">Sales &amp; Support</h2>
            <p class="cont_info">
              Our customer support team is available{" "}
              <a class="email-phone" href="tel:+18001212207">
                {" "}
                <strong> 1800 121 2207</strong>
              </a>{" "}
              from 9 am to 6 pm.
            </p>
          </div>
        </article>
        <article class="complaints page-width">
          <div class="txt-container info-container">
            <h2 class="ref">Complaints</h2>
            <p class="cont_info">
              For any complaints or any concerns faced by you mail us at{" "}
              <strong>
                {" "}
                <a class="email-phone" href="mailto: enquiry@remarkev.com">
                  {" "}
                  enquiry@remarkev.com{" "}
                </a>{" "}
              </strong>
              .
            </p>
          </div>
        </article>
        <article class="registerd-address page-width">
          <div class="txt-container info-container">
            <h2 class="ref">Registered Office</h2>
            <address class="cont_info">
              A-13, Industrial Sector, A-7, Part- 1, Trans Delhi Signature City,
              Ghaziabad, Uttar Pradesh 201102
            </address>
          </div>
        </article>
        <article class="help page-width  info-text bg-dark">
          <h2 class="secondary">Need Help?</h2>
          <div id="help_one" class="txt-container info-container support ">
            <div class="help-item">
              <h3 class="tertiary">Support</h3>
              <p>
                <a class="email-phone" href="mailto: support@remarkev.com">
                  support@remarkev.com
                </a>
              </p>
            </div>
            <div class="help-item">
              <h3 class="tertiary">Enquiry</h3>
              <p>
                <a class="email-phone" href="mailto: enquiry@remarkev.com">
                  enquiry@remarkev.com
                </a>
              </p>
            </div>
            <div class="help-item">
              <h3 class="tertiary">Sales and customer support</h3>
              <p>
                {" "}
                <a class="email-phone" href="tel:+18002098282">
                  1800 121 2207
                </a>
              </p>
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default Contact;
