import React, { useRef, useEffect, } from "react";
import { Link } from "react-router-dom";

function Header() {
  const headerRef = useRef(undefined);

  const handleHamClick = () => {
    headerRef.current.classList.toggle("translate-out");
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 650) {
      headerRef.current.classList.add("translate-out");
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 650) {
      headerRef.current.classList.add("translate-out");
    }
  }, [window.innerWidth]);

  return (
    <>
      <div className="ham-menu" onClick={handleHamClick}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6Z"
            fill="black"
          />
          <path
            d="M2 12.0322C2 11.4799 2.44772 11.0322 3 11.0322H21C21.5523 11.0322 22 11.4799 22 12.0322C22 12.5845 21.5523 13.0322 21 13.0322H3C2.44772 13.0322 2 12.5845 2 12.0322Z"
            fill="black"
          />
          <path
            d="M3 17.0645C2.44772 17.0645 2 17.5122 2 18.0645C2 18.6167 2.44772 19.0645 3 19.0645H21C21.5523 19.0645 22 18.6167 22 18.0645C22 17.5122 21.5523 17.0645 21 17.0645H3Z"
            fill="black"
          />
        </svg>
      </div>

      <header className="" ref={headerRef}>
        <nav>
          <div className="logo-container">
            <div className="logo">
              <Link onClick={handleLinkClick} to="/">
                <img class="remark"src="/assets/logo.png" alt="logo" />
              </Link>
            </div>
          </div>

          <ul className="main-links-container">
            <li className="main-link" data-animname="fade-in-up" data-delay="">
              <Link onClick={handleLinkClick} to="/about">
                ABOUT US
              </Link>
            </li>
            <li
              className="hasSubLinks main-link"
              data-animname="fade-in-up"
              data-delay=".1"
            >
              HIGH SPEED SCOOTER
              <ul data-target="products" className="subLinksContainer">
              <li
                  className="sublink"
                  data-animname="fade-in-up"
                  data-delay=".3"
                >
                  <Link onClick={handleLinkClick} to="/product/electiva-star">
                     ELECTIVA STAR
                  </Link>
                </li>
                <li
                  className="sublink"
                  data-animname="fade-in-up"
                  data-delay=".3"
                >
                  <Link onClick={handleLinkClick} to="/product/electiva-gold">
                     ELECTIVA GOLD
                  </Link>
                </li>
              </ul>
            </li>

            <li
              className="hasSubLinks main-link"
              data-animname="fade-in-up"
              data-delay=".1"
            >
              LOW SPEED SCOOTERS
              <ul data-target="products" className="subLinksContainer">
                <li
                  className="sublink"
                  data-animname="fade-in-up"
                  data-delay=".3"
                >
                  <Link onClick={handleLinkClick} to="/product/aten">
                    ATEN
                  </Link>
                </li>
                <li
                  className="sublink"
                  data-animname="fade-in-up"
                  data-delay=".3"
                >
                  <Link onClick={handleLinkClick} to="/product/aten-plus">
                    ATEN PLUS
                  </Link>
                </li>
                <li
                  className="sublink"
                  data-animname="fade-in-up"
                  data-delay=".3"
                >
                  <Link onClick={handleLinkClick} to="/product/hiphop">
                    HIP HOP
                  </Link>
                </li>
                <li
                  className="sublink"
                  data-animname="fade-in-up"
                  data-delay=".3"
                >
                  <Link onClick={handleLinkClick} to="/product/hiphop-plus">
                  HIP HOP PLUS
                  </Link>
                </li>
                <li
                  className="sublink"
                  data-animname="fade-in-up"
                  data-delay=".3"
                >
                  <Link onClick={handleLinkClick} to="/product/hiphop-dlx">
                  HIP HOP DLX
                  </Link>
                </li>
                <li
                  className="sublink"
                  data-animname="fade-in-up"
                  data-delay=".3"
                >
                  <Link onClick={handleLinkClick} to="/product/hi-energy">
                     HI ENERGY
                  </Link>
                </li>
                <li
                  className="sublink"
                  data-animname="fade-in-up"
                  data-delay=".3"
                >
                  <Link onClick={handleLinkClick} to="/product/hi-energy-plus">
                  HI ENERGY PLUS
                  </Link>
                </li>
                <li
                  className="sublink"
                  data-animname="fade-in-up"
                  data-delay=".3"
                >
                  <Link onClick={handleLinkClick} to="/product/hi-energy-dlx">
                  HI ENERGY LD
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className="main-link"
              data-animname="fade-in-up"
              data-delay=".2"
            >
              <Link onClick={handleLinkClick} to="/locate">
                LOCATE EXPERIENCE STORE
              </Link>
            </li>
            <li
              className="main-link"
              data-animname="fade-in-up"
              data-delay=".3"
            >
              <Link onClick={handleLinkClick} to="/partner">
                PARTNER WITH US
              </Link>
            </li>
            <li
              className="main-link"
              data-animname="fade-in-up"
              data-delay=".4"
            >
              <button>
              <a href="/prebook">
                <img className="remark"src="/assets/electric-sign.png" alt="Electric Sign" />
                </a>
              </button>
            </li>
          </ul>
        </nav>
      </header>
      <aside id="get-in-touch">
        <p class="btn touch-btn">Get in Touch</p>
        <div class="container">
          <div class="get">Get in Touch</div>
          <div class="btn-container"><div>
            <a class="btn" href="/partner">Become a Dealer</a>
            </div>
            <div>
              <a class="btn" href="/locate">Find Store</a>
              </div>
              <div><a class="btn" href="/contact">Contact Us</a>
              </div>
              </div>
              </div>
              </aside>
    </>
  );
}

export default Header;
