import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import DatasheetDownload from "../miniComponents/DatasheetDownload";

function ProductVariants({ data }) {
  const { variants } = data;

  const [currentBike, setCurrentBike] = useState(variants[0].image);
  const [currentTitle, setCurrentTitle] = useState(variants[0].heading);
  const bikeAreaRef = useRef(undefined);
  let t = undefined;

  const handleClick = (e) => {
    const { image, bgcolor, heading } = e.target.dataset;

    setCurrentBike(image);
    setCurrentTitle(heading);
  };

  const applyAnimation = (element, animation) => {
    element.classList.add(animation);

    if (t) clearTimeout(t);
    t = setTimeout(() => {
      element.classList.remove(animation);
    }, 200);
  };

  useEffect(() => {
    if (bikeAreaRef.current) applyAnimation(bikeAreaRef.current, `fade-in`);
  }, [currentBike]);

  return (
    <section
      data-animname="fade-in-up"
      title="Color Variants"
      id="product-variants"
    >
      <h2 className="heading">COLORS</h2>

      <article>
        <div ref={bikeAreaRef} className="bike-area fade-in ">
          <img src={`/assets/${currentBike}`} alt={`${currentBike}`} />
        </div>
        <div className="options-area">
          <h3 className="title">{currentTitle}</h3>
          <div className="options-container">
            {variants.map(({ image, heading, bgColor }, index) => (
              <button
                className="variant"
                style={{ backgroundColor: bgColor }}
                key={index}
                data-image={image}
                data-heading={heading}
                onClick={handleClick}
              ></button>
            ))}
          </div>

          <Link className="btn" to="/enquire">
            Enquire Now
          </Link>

          <DatasheetDownload />
        </div>
      </article>
    </section>
  );
}

export default ProductVariants;
