import React, { useState, useEffect } from "react";
import Calculator from "./Calculator";
import FeaturedSlider from "./miniComponents/FeaturedSlider";
import Hero_slider from "./Hero_slider";
import Feature from "./miniComponents/Feature";
import animationObserver from "../library/animationObserver";
import Battery from "../assets-src/batterys.png";
import Power from "../assets-src/power-electronics.png";
import Motor from "../assets-src/motor.png";
import { Helmet } from "react-helmet";

function Home() {
  const [selectedImage, setSelectedImage] = useState(Battery);

  useEffect(() => {
    animationObserver();
  }, []);

  const dataForHeroSlider = [
    {
      image: "hero-slider-1.png",
      textData: [
        {
          color: "#fff",
          heading: "3-8 hrs",
          content: `Charge (0%-100%)`,
        },
        {
          color: "#fff",
          heading: "3 yrs",
          content: "Battery and Motor Warranty",
        },
      ],
    },
    {
      image: "hero-slider-1.png",
      textData: [
        {
          color: "#fff",
          heading: "3-8 hrs",
          content: `Charge (0%-100%)`,
        },
        {
          color: "#fff",
          heading: "3 yrs",
          content: "Battery and Motor Warranty",
        },
      ],
    },
    {
      image: "hero-slider-1.png",
      textData: [
        {
          color: "#fff",
          heading: "3-8 hrs",
          content: `Charge (0%-100%)`,
        },
        {
          color: "#fff",
          heading: "3 yrs",
          content: "Battery and Motor Warranty",
        },
      ],
    },
    {
      image: "hero-slider-1.png",
      textData: [
        {
          color: "#fff",
          heading: "3-8 hrs",
          content: `Charge (0%-100%)`,
        },
        {
          color: "#fff",
          heading: "3 yrs",
          content: "Battery and Motor Warranty",
        },
      ],
    },
    {
      image: "hero-slider-1.png",
      textData: [
        {
          color: "#fff",
          heading: "3-8 hrs",
          content: `Charge (0%-100%)`,
        },
        {
          color: "#fff",
          heading: "3 yrs",
          content: "Battery and Motor Warranty",
        },
      ],
    },
    {
      image: "hero-slider-1.png",
      textData: [
        {
          color: "#fff",
          heading: "3-8 hrs",
          content: `Charge (0%-100%)`,
        },
        {
          color: "#fff",
          heading: "3 yrs",
          content: "Battery and Motor Warranty",
        },
      ],
    },
    {
      image: "hero-slider-1.png",
      textData: [
        {
          color: "#fff",
          heading: "3-8 hrs",
          content: `Charge (0%-100%)`,
        },
        {
          color: "#fff",
          heading: "3 yrs",
          content: "Battery and Motor Warranty",
        },
      ],
    },
  ];

  const featureData = [
    {
      image: "icon-cruse-control.webp",
      heading: "CRUISE CONTROL",
    },
    {
      image: "icon-central-lock.webp",
      heading: "CENTRAL LOCK",
    },
    {
      image: "icon-child-lock.webp",
      heading: "CHILD LOCK",
    },
  ];

  return (
      <main id="home">
          <div>
              <Helmet>
                  <title>Top Electric Scooter Manufacturers or Supplier in Delhi, India</title>
                  <meta name="description" content="Discover the leading electric scooter manufacturers and suppliers company in Delhi, India. Explore top-quality, innovative e-scooters designed for efficiency and sustainability."/>
                      <meta name="keywords" content="top electric scooter manufacturers in India, best e Scooter Company in India, buy electric scooter in Delhi"/>
                          <link rel="canonical" href=" https://www.remarkev.com " />
              </Helmet>
              
          </div>
      {/* <!-- hero section --> */}
      <section data-animname="fade-in-up" title="Hero" id="hero">
        <Hero_slider dataForHeroSlider={dataForHeroSlider} />
      </section>

      {/* <!-- design section --> */}
      <section title="Design" id="design">
        <div className="feature-hero-container">
          <div data-animname="fade-in-up" className="img-container">
            <img
              className="img-1"
              src="./assets/design-img-edited.png"
              alt="design"
            />
          </div>
          {/* <!-- <img className="img-2" src="./assets/design-img-right.png" alt="design-img" /> --> */}
          <h2 data-animname="fade-in-up" data-delay=".3" className="heading">
            ACCELERATE INTO THE FUTURE
          </h2>
        </div>

        <div className="data-container page-width">
          <div className="headings">
            <h2 className="heading">DESIGN</h2>
            <p className="rte">Fun is where you choose to begin!</p>
          </div>

          <div className="features">
            <div
              data-animname="fade-in-up"
              data-delay=""
              className="feature feature-1"
            >
              <div className="img-container">
                <img src="./assets/icon-scooter.png" alt="scooter icon" />
              </div>
              <div className="txt-container" id="feature_head">
                <h3>DESIGN</h3>
                <p>Seamless Style. Edgy Looks. Bold Colours.</p>
              </div>
            </div>

            <div
              data-animname="fade-in-up"
              data-delay=".2"
              className="feature feature-2"
            >
              <div className="img-container">
                <img src="./assets/icon-build.png" alt="build icon" />
              </div>
              <div className="txt-container" id="feature_head">
                <h3>BUILD</h3>
                <p className="rte">
                  Steel Chassis for Maximum Rigidity. Precision pressed steel
                  body panels.
                </p>
              </div>
            </div>

            <div
              data-animname="fade-in-up"
              data-delay=".4"
              className="feature feature-3"
            >
              <div className="img-container">
                <img src="./assets/icon-battery.png" alt="battery icon" />
              </div>
              <div className="txt-container" id="feature_head">
                <h3>RANGE</h3>
                <p>95km on a single charge. 0 to 25% in 1 Hour.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- technology section --> */}
      <section title="Technology" id="technology">
        <h2 className="heading">TECHNOLOGY</h2>

        <div className="top-area">
          <p className="rte">
            Find all in one, with your perfect ride! <br />
            Keeping in mind all the safety features plus the comfort you
            deserve, our engineers and mechanical staff makes sure they deliver
            a premium quality to every scooter with every purchase!
          </p>
        </div>

        <div className="buttons-bar bottom-border">
          <button onClick={() => setSelectedImage(Battery)} className="btn">
            BATTERY
          </button>
          <button
            onClick={() => setSelectedImage(Power)}
            className="btn"
            name="power-electronics"
          >
            POWER ELECTRONICS
          </button>
          <button
            onClick={() => setSelectedImage(Motor)}
            className="btn"
            name="motor"
          >
            MOTOR
          </button>
        </div>

        <div data-animname="fade-in-up" className="img-container fade-in-up">
          <img src={selectedImage} class="display-out" alt="battery" />
        </div>

        <div className="bottom-area">
          <p className="rte bottom-border">
            Experience the power of SMF/Lithium battery in your electric
            scooters that not only charges it upto mark but is also designed to
            function under extreme <br /> environmental conditions.
          </p>
        </div>
      </section>

      {/* <!-- features slider section --> */}
      {/* <FeaturedSlider
        settings={{
          infinite: true,
          speed: 1500,
          fade: false,
          dots: true,
          cssEase: "ease",
          autoplay: true,
          autoplaySpeed: 3000,
        }}
        data={{
          heading: "FEATURES",

          sliderData: [
            {
              txt: "LED Headlamps",
              image: "hero2.jpg",
            },
            {
              txt: "Alloy Wheels with Telecopic Suspension and wheels",
              image: "hero3.jpg",
            },
            {
              txt:"Handicraft Leather sheet",  
              image: "hero4.jpg",
            },
            {
              txt: "LED TailLamps",  
              image: "hero5.jpg",
            },
          ],
        }}
      /> */}

      {/* <!-- secure section --> */}
      <section title="Secure" id="secure">
        <h3 className="heading">R-SECURE</h3>
        <p className="rte">Safety par Compromise Nahi!</p>

        {/* features */}
        <div className="features" style={{ backgroundColor: "#001119" }}>
          {featureData.map((data, index) => {
            const { image, heading, description } = data;

            return (
              <Feature
                key={index}
                index={index}
                image={image}
                heading={heading}
                description={description}
              />
            );
          })}
        </div>
      </section>

      {/* <!-- feature image --> */}
      <section
        title="Featured section"
        id="featured-section"
        className="featured-section"
        data-animname="fade-in-up"
      >
        <div className="img-container">
          <img src="./assets/feature-img-1.png" alt="feature" />
        </div>
      </section>

      {/* <!-- calculator section --> */}
      <Calculator />

      {/* <!-- why section --> */}
      <section data-animname="fade-in-up" title="Why" id="why">
        <div className="img-container">
          <img src="./assets/hero.jpg" alt="man-on-scooter" />
        </div>
        <div className="data-container">
          <h1>Why should you consider an Electric Scooter?</h1>
          <p className="rte">
            There is no denial to the increase in the level of pollution in the
            country. What’s better than switching to a ecological ride taking
            you places.
          </p>

          <div className="icons-contaier">
            <div data-animname="fade-in-up" data-delay="" className="icon-item">
              <div className="icon">
                <img
                  src="./assets/icon-health-benifits.png"
                  alt="icon-health-benifits"
                />
              </div>
              <p>
                Increased <br />
                Health <br />
                Benefits
              </p>
            </div>

            <div
              data-animname="fade-in-up"
              data-delay=".2"
              className="icon-item"
            >
              <div className="icon">
                <img
                  src="./assets/icon-sustainability.png"
                  alt="icon-sustainability.png"
                />
              </div>
              <p>
                Planet <br />
                Sustainability
              </p>
            </div>

            <div
              data-animname="fade-in-up"
              data-delay=".4"
              className="icon-item"
            >
              <div className="icon">
                <img src="./assets/icon-cmvr.png" alt="icon-cmvr.png" />
              </div>
              <p>
                CMVR <br />
                Exempted
              </p>
            </div>

            <div
              data-animname="fade-in-up"
              data-delay=".6"
              className="icon-item"
            >
              <div className="icon">
                <img
                  src="./assets/icon-reduced-cost.png"
                  alt="icon-reduced-cost.png"
                />
              </div>
              <p>
                Reduced <br />
                cost of transportation <br />
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* utltimate section */}
      <section>
        <div className="container">
          <h3 className="fw-bold">
            Remark: The Ultimate Destination to Buy Electric Scooters in Delhi
          </h3>
          <p className="fw-medium my-3">
            Welcome to Remark, where the future of urban mobility is redefined.
            As the best electric scooter supplier in India and one of the top
            electric scooter manufacturers, we are committed to revolutionizing
            the way you travel in Delhi and beyond. Our electric scooters are a
            blend of innovative technology, unmatched quality, and eco-friendly
            solutions, making us the go-to choice for conscious commuters.
          </p>
          <h3 className="fw-bold">
            Experience the Best with Remark's Electric Scooters
          </h3>
          <p className="mt-3">
            <strong>Innovative Design and Technology:</strong> At Remark, we
            believe in staying ahead of the curve. Our electric scooters are
            designed with cutting-edge technology, offering efficiency,
            durability, and a seamless riding experience. With features like
            advanced battery management systems, regenerative braking, and
            intuitive controls, our scooters are not just vehicles but a leap
            into the future of transportation.
          </p>
          <p className="my-4">
            <strong>Eco-Friendly and Sustainable:</strong> In our quest to be
            the best electric scooter supplier in India, sustainability is at
            our core. Our electric scooters provide a green alternative to
            traditional transportation, significantly reducing carbon emissions
            and contributing to a cleaner environment.
          </p>
          <p>
            <strong>Unparalleled Customer Service:</strong> Remark prides itself
            on exceptional customer service. From the moment you consider buying
            an electric scooter in Delhi, our team is here to guide you through
            every step. We offer personalized consultations, test rides, and
            after-sales support to ensure your experience with us is nothing
            short of remarkable.
          </p>
          <p className="my-4">
            <strong>Widely recognized and trusted:</strong> As one of the top
            electric scooter manufacturers in India, our reputation speaks for
            itself. We have been recognized for our innovation, quality, and
            commitment to customer satisfaction. Join the growing family of
            happy customers who have made the switch to electric with Remark.
          </p>
        </div>
      </section>
      {/* join section */}
      <section className="my-3">
        <div className="container">
          <h3 className="fw-bold">Join the Remark Revolution in Delhi</h3>
          <p className="my-4">
            Are you ready to be part of the electric revolution in Delhi? Remark
            offers the perfect blend of style, sustainability, and performance.
            Whether you're navigating the bustling streets of Delhi or enjoying
            a serene ride in its outskirts, our electric scooters are your ideal
            companion.
          </p>
          <p className="my-4">
            Visit us at Remark to explore our range of electric scooters and
            take the first step towards a greener, smarter, and more efficient
            way of commuting. Embrace the future of mobility with Remark - where
            every ride is an experience in itself.
          </p>
        </div>
      </section>
    </main>
  );
}

export default Home;
